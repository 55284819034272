<template>
    <div class="container fluid">
        <div class="row">

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" clearable dense :disabled="disabled_region == true"  @change="(event) => updateOfficeDropdown(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch" clearable dense :disabled="disabled_office == true" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-2 col-8">
                                <v-select v-model="usersModel" :items="usersLists" default="" item-value="user_id" item-text="user_id" label="Salesman" clearable dense :disabled="disabled_users == true" prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-1 col-4">
                                <v-select v-model="pr_statModel" :items="pr_statLists" default="" item-value="stat" item-text="stat_text" label="Status" clearable dense prepend-icon="mdi-check-circle-outline"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-1 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12" style="box-shadow: 0px 6px 18px rgba(var(--shadow-rgb), .05);">

                <div id="app" class="app-container">
                    <div class="sidebar">
                        <div class="sidebar-header">
                            <h3>Project List</h3>
                        </div>
                        <div class="search-chat">
                            <input type="text" v-model="searchQuery" placeholder="Search project..." style="width: 100%; height: 40px; padding: 10px; background-color: #2A3942; border: none; border-radius: 5px; color: #fff;">
                        </div>
                        <div class="chat-list-container">
                            <ul>
                            <li
                                v-for="chat in filteredChats"
                                :key="chat.id"
                                @click="selectChat(chat)"
                                :class="{ active: chat.id === selectedChat?.id }"
                            >
                                <div class="chat-item">
                                <div class="avatar">{{ chat.name.charAt(0) }}</div>
                                <div class="chat-details">
                                    <div class="chat-name">{{ chat.name }}</div>
                                    <div class="last-message">
                                    {{ chat.messages[chat.messages.length - 1]?.text || "No messages yet" }}
                                    </div>
                                </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                    </div>
                
                    <div class="chat-window">
                        <div v-if="selectedChat">
                        <div class="chat-header">
                            <h3>{{ selectedChat.name }}</h3>
                        </div>
                        <div class="messages-container" ref="messagesContainer">
                            <div class="messages">
                                <div class="message" v-for="(msg, index) in selectedChat.messages":key="index" :class="{ 'message-sender': msg.isSender }">
                                    <span class="message-name">{{ msg.isSender ? 'You' : selectedChat.salesman }}</span>
                                    <div class="message-content">
                                        <span class="message-text">{{ msg.text }}</span>
                                        <span class="message-time">{{ msg.time }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="message-input">
                            <input
                            v-model="newMessage"
                            @keyup.enter="sendMessage"
                            type="text"
                            placeholder="Type a message..."
                            />
                            <button @click="sendMessage">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 96 960 960"
                                width="24"
                            >
                                <path
                                d="M120 936v-240L600 576 120 456V216l720 360-720 360Z"
                                fill="#fff"
                                />
                            </svg>
                            </button>
                        </div>
                        </div>
                        <div v-else class="no-chat">
                        <p>Select a project on the list for progress discussion</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        // chats: [],
        chats: [
          {
            id: 1,
            name: "PT. HUATHAI CONSTRUCTION INDONESIA",
            salesman: "ERWIN KOTO",
            messages: [
              { text: "PENAWARAN UNTUK PT. HUATHAI CONTRUCTION INDONESIA DI PEMALANG, ATAP TYPE KR-5 TEBAL 0,35 0,40 AZ.150 DAN ECOTRIM TEBAL 0,35 0,40 AZ.150. SAYA TAWARKAN DARI PL PRY - 8%. HARGA SEDANG DIAJUKAN KE OWNER", isSender: false, time: "01:00 pm 12-01-2024" },
              { text: "ini gmn lanjutnya?", isSender: true, time: "02:00 pm 12-01-2024" },
            ],
          },
          {
            id: 2,
            name: "CV. BEKA INDONESIA CABANG PEMALANG",
            salesman: "ERWIN KOTO",
            messages: [],
          },
        ],
        selectedChat: null,
        newMessage: '',
        searchQuery: '',
      };
    },
    methods: {
      selectChat(chat) {
        this.selectedChat = chat;
        this.$nextTick(() => {
            const container = this.$refs.messagesContainer;
            container.scrollTop = container.scrollHeight;
        });
      },
      sendMessage() {
        if (this.newMessage.trim() && this.selectedChat) {
            this.selectedChat.messages.push({ text: this.newMessage.trim(), isSender: true });
            this.newMessage = "";
            this.$nextTick(() => {
                const container = this.$refs.messagesContainer;
                container.scrollTop = container.scrollHeight;
            });
        }
      },
    },
    computed: {
        filteredChats() {
        return this.chats.filter((chat) => {
            return chat.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                chat.messages.some((message) => message.text.toLowerCase().includes(this.searchQuery.toLowerCase()));
        });
        },
    },
  };
  </script>
  
<style>
    .app-container {
        display: flex;
        max-height: 70vh;
        background: #f6f7fb;
        font-family: "Arial", sans-serif;
    }
  
    .sidebar {
        width: 25%;
        background: #111B21;
        border-right: 1px solid #202C33;
        display: flex;
        flex-direction: column;
    }
    
    .sidebar-header {
        padding: 5px;
        background: #111B21;
        color: #fff;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
    }

    .chat-list-container {
        flex: 1;
        overflow-y: auto;
        min-height: 200px;
    }

    
    .sidebar ul {
        list-style: none;
        padding: 0;
        margin: 0;
        flex: 1;
        overflow-y: auto;
        min-height: 50vh;
    }
    
    .sidebar li {
        padding: 10px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        transition: background-color 0.2s ease;
    }
    
    .sidebar li:hover {
        background: #f9f9f9;
    }
    
    .sidebar li.active {
        background: #e0f7fa;
    }
    
    .chat-item {
        display: flex;
        align-items: center;
    }
    
    .avatar {
        width: 40px;
        height: 40px;
        background: #3455eb;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 1.2em;
    }
    
    .chat-details {
        flex: 1;
    }
    
    .chat-name {
        font-weight: bold;
        color: #fff;
    }
    
    .last-message {
        font-size: 0.9em;
        color: #fff;
    }
    
    .chat-window {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: #0B141A;
        position: relative;
    }

    .messages-container {
        flex: 1;
        overflow-y: auto;
        padding: 10px;
        max-height: 54vh;
    }
    
    .chat-header {
        padding: 10px;
        background: #202C33;
        color: #fff;
        font-size: 1.3em;
    }

    .messages {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }
    
    .message {
        background: #e0f7fa;
        padding: 10px 15px;
        margin: 5px 0;
        border-radius: 10px;
        max-width: 60%;
        position: relative;
    }

    .message-sender {
        background: #d1f3d1;
        align-self: flex-end;
        text-align: right;
    }

    .message-content {
        display: flex;
        flex-direction: column;
    }

    .message-time {
        font-size: 0.8em;
        color: #808080;
        margin-top: 5px;
        align-self: flex-end;
    }

    .message-input {
        display: flex;
        align-items: center;
        padding: 10px;
        background: #202C33;
        border-top: 1px solid #202C33;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    
    .message-input input {
        flex: 1;
        padding: 10px;
        border: 1px solid #2A3942;
        background: #2A3942;
        border-radius: 20px;
        outline: none;
        color: #D1D7DB;
    }
    
    .message-input button {
        margin-left: 10px;
        background: #00bfa5;
        border: none;
        border-radius: 50%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    .message-input button svg {
        fill: #fff;
    }
    
    .message-input button:hover {
        background: #00796b;
    }
    
    .no-chat {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #808080;
        font-size: 1.2em;
    }

    .sidebar li.active {
        background: #2A3942;
    }

    .message-name {
        font-weight: bold;
        font-size: 0.9em;
        margin-bottom: 5px;
        display: block;
    }

    .search-chat {
        padding: 15px;
        border-bottom: 1px solid #ccc;
    }

    #search-chat-input {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    #chat-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    #chat-list li {
        padding: 10px;
        border-bottom: 1px solid #ccc;
    }

    #chat-list li:hover {
        background-color: #f0f0f0;
    }

</style>
  